import {useInView} from 'framer-motion'
import {useEffect, useRef} from 'react'
import {useStore} from '~/utils/store'

interface Props {
  children: JSX.Element | JSX.Element[]
  Component?: 'section' | 'div'
  className?: string
  headerColor: string
  style?: object
}

export function HeaderColorWrapper({
  children,
  Component = 'section',
  className = '',
  headerColor,
  style = {},
  ...props
}: Props) {
  const ref = useRef<HTMLDivElement>(null!)

  const headerRootMargin = useStore(({headerRootMargin}) => headerRootMargin)

  const setHeaderColor = useStore(({setHeaderColor}) => setHeaderColor)

  const isInView = useInView(ref, {
    once: false,
    margin: headerRootMargin ? headerRootMargin : '0px',
  })

  useEffect(() => {
    if (isInView) {
      setHeaderColor(headerColor)
    }
  }, [isInView])

  return (
    <Component className={className} ref={ref} style={style} {...props}>
      {children}
    </Component>
  )
}
