import {forwardRef} from 'react'
import {getImageUrl} from '~/utils/image-url'

const BREAKPOINTS = [640, 768, 1024, 1280, 1536] // px

export const findLastNonNullValue = (items: any, currentIndex: number) => {
  const sliced = items.slice(0, currentIndex)
  return sliced.filter((val: any) => val !== null).pop()
}

// Generate srcset sizes based off breakpoints

const generateSrcSet = (urlBuilder: any, breakpoints: any, {quality}: any) => {
  return breakpoints
    .map((width: any) => {
      return `${urlBuilder.width(width).auto('format').quality(quality)} ${width}w`
    })
    .join(', ')
}

// Generate srcset sizes based off breakpoints
const generateSizes = (breakpoints, sizes) => {
  if (!sizes) {
    return undefined
  }

  if (typeof sizes === 'string') {
    return sizes
  }

  if (sizes.length === 1 && sizes[0] !== null) {
    return sizes[0]
  }

  return sizes
    .map((val, i) => {
      if (i === sizes.length - 1) {
        return sizes[i]
      }

      let current = val
      if (val === null) {
        current = findLastNonNullValue(sizes, i)
      }

      return `(max-width: ${breakpoints?.[i]}px) ${current}`
    })
    .join(', ')
}

interface ImageInterface {
  src: any
  sizes?: any
  alt?: string
  quality?: number
  className?: string
}

export default forwardRef(function Image(
  {src, alt = 'wakey', sizes = ['50vw, 100vw'], quality = 80, className},
  ref: ImageInterface
) {
  if (!src) {
    return null
  }

  const urlBuilder = getImageUrl(src)

  // Generate srcset + sizes
  const srcSetSizes = generateSizes(BREAKPOINTS, sizes)

  const srcSet = generateSrcSet(urlBuilder, BREAKPOINTS, {quality})

  let urlDefault = urlBuilder.blur(80).url()
  let width = urlBuilder?.options?.source?.asset?.metadata?.dimensions?.width
  let height = urlBuilder?.options?.source?.asset?.metadata?.dimensions?.height

  return (
    <img
      ref={ref}
      // decoding="async"
      loading="lazy"
      sizes={srcSetSizes}
      src={urlDefault}
      srcSet={srcSet}
      alt={alt}
      width={width}
      height={height}
      className={className}
    />
  )
})
