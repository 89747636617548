export const eas = {
  inQuad: [0.26, 0, 0.6, 0.2],
  outQuad: [0.4, 0.8, 0.74, 1],
  inOutQuad: [0.48, 0.04, 0.52, 0.96],

  inCubic: [0.4, 0, 0.68, 0.06],
  outCubic: [0.34, 1.02, 0.68, 1],
  inOutCubic: [0.66, 0, 0.34, 1],

  inQuart: [0.52, 0, 0.74, 0],
  outQuart: [0.26, 1.04, 0.54, 1],
  inOutQuart: [0.77, 0.0, 0.175, 1.0],

  inExpo: [0.66, 0, 0.86, 0],
  outExpo: [0.16, 1.08, 0.38, 0.98],
  inOutExpo: [0.9, 0, 0.1, 1],

  inBack: [0.6, -0.28, 0.735, 0.045],
  outBack: [0.175, 0.885, 0.32, 1.275],
  inOutBack: [0.175, 0.885, 0.32, 1.275],

  wakey: [0.45, 0.02, 0.09, 0.98],
}
