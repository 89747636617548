import {useRef} from 'react'
import {m} from 'framer-motion'
import {clsx} from 'clsx'

import RichText from './rich-text'
import {eas} from '~/utils/easings'

interface Types {
  title: string
  text: Array<any>
  open?: boolean
  index?: number
  onButtonClick: any
}

export default function Accordion({title, text, open = false, index, onButtonClick}: Types) {
  const accordionRef = useRef<HTMLDivElement>(null)

  return (
    <li className={clsx('border-b-[0.05rem] border-black/10', index === 0 && 'border-t-[0.05rem]')}>
      <button
        onClick={onButtonClick}
        className="tracking-pn-3 flex w-full justify-between py-[2.3rem] text-[2rem] leading-[130%] md:py-[1.95rem]"
      >
        <span className="text-left">{title}</span>{' '}
        <span className="w-[6rem] pl-[2rem] text-right">{open ? '-' : '+'}</span>
      </button>
      <m.div
        ref={accordionRef}
        className={clsx('block h-0 overflow-hidden')}
        initial={{height: open ? 'auto' : 0}}
        animate={{height: open ? 'auto' : 0}}
        transition={{ease: eas.outCubic, duration: 0.4}}
      >
        {text && <RichText data={text} className="pb-c-1 text-base md:pb-[2.6rem]" />}
      </m.div>
    </li>
  )
}
