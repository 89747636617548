export function getSeoMetaData({page, fallback, location}) {
  const title = page?.title ? page?.title : fallback.title
  const description = page?.description ? page?.description : fallback.description
  const image = page?.image ? page?.image : fallback.image

  const url = `https://www.wakey.care${location.pathname}`
  return {
    title,
    description,
    // OG
    'og:title': title,
    'og:description': description,
    'og:image': image,
    'og:url': url,
    // TWITTER
    'twitter:card': 'summary_large_image', // summary, summary_large_image, app, player
    'twitter:creator': '@wakey.care',
    'twitter:url': url,
    'twitter:title': title,
    'twitter:description': description,
    'twitter:image': image,
  }
}
